import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getOrder,
  insertOrder,
  updateOrder,
  calculateCampaignDiscount,
  executeOnayHierarchy,
  executeOnayIptal,
  executeSapIslemleri,
} from "../../redux/actions/act-order/orderActions";
import OrderProps from "./OrderProps";
import OrderProductList from "./OrderProductList";
import { fetchDealerProductGroup } from "../../redux/actions/act-bry-dealerProductGroup/dealerProductGroupActions";
import OrderReport from "./OrderReport";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { getOrderProducts } from "../../redux/actions/act-order/orderProductActions";
import { BASE_URL } from "../../config/globalVariables";
import { fetchOrderFiles } from "../../redux/actions/act-order/orderFileActions";
import { defaultAlertMessages, getWarningMessage } from "../../helpers/utils";
import { confirmAlert } from "react-confirm-alert";
import OrderAddressInfo from "./OrderAddressInfo";
import OrderResposiblePerson from "./OrderResposiblePerson";
import OrderCopy from "./OrderCopy";
import { OrderFiles } from "./OrderFiles";
import { getUserSalesRegions } from "../../redux/actions/act-userSalesRegion/userSalesRegionActions";
import CustomerAddressUpdate from "../cmp-customer/CustomerAddressUpdate";
import "../../css/siparisEkrani.css";

class OrderUpdate extends Component {
  isSendForApproval = false;
  isAddedOrder = false;
  isCancelApproval = false;

  state = {
    visibleProductList: false,
    visibleOrderReport: false,
    visibleOrderFiles: false,
    visibleDaikinOrderFiles: false,
    visibleSalesApproveFile: false,
    visibleAddressInfo: false,
    visibleAddressInsert: false,
    visibleResponsiblePerson: false,
    visibleOrderRPAReport: false,
    visibleOrderCopy: false,
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.props.match.params.orderId === "0") {
      this.isAddedOrder = true;
      this.props.insertOrder(this.props.order);
    } else {
      if (this.props.order._order.sevK_ADRESI_TIPI != "YENI_ADRES") {
        this.props.updateOrder(this.props.order);
      } else {
        defaultAlertMessages.MessageDetailWarning =
          "Sevk Adres Tipi YENI ADRES seçilidir. Lütfen Adres Bilgilerini giriniz.";
        defaultAlertMessages.Ok = "Tamam";

        confirmAlert({
          customUI: ({ onClose }) => {
            return getWarningMessage(onClose, defaultAlertMessages);
          },
        });
      }
    }
  };

  componentDidUpdate() {
    if (
      this.props.order.dataInserted === true &&
      this.props.match.params.orderId === "0" &&
      this.isAddedOrder === true
    ) {
      this.props.history.push(
        BASE_URL + "order-update/" + this.props.order._order.brY_SIPARIS_ID
      );
    }

    if (this.isSendForApproval && this.props.orderApproval.dataUpdated) {
      this.props.history.push(BASE_URL + "order");
    }
  }

  componentDidMount() {
    this.props.getOrder(this.props.match.params.orderId);
    this.props.fetchDealerProductGroup();
    this.refreshProductList();
    this.props.getUserSalesRegions();
    if (this.props.match.params.orderId !== "0") {
      this.getOrderFiles();
    }
  }

  getOrderFiles = () => {
    this.props.fetchOrderFiles(this.props.match.params.orderId);
  };

  toggleProductList = () => {
    this.setState({ visibleProductList: !this.state.visibleProductList });
  };

  toggleDialogOrderReport = () => {
    this.setState({
      visibleOrderReport: !this.state.visibleOrderReport,
    });
  };

  toggleDialogOrderRPAReport = () => {
    this.setState({
      visibleOrderRPAReport: !this.state.visibleOrderRPAReport,
    });
  };

  toggleDialogOrderFiles = () => {
    this.setState({
      visibleOrderFiles: !this.state.visibleOrderFiles,
    });
  };

  toggleDialogDaikinOrderFiles = () => {
    this.setState({
      visibleDaikinOrderFiles: !this.state.visibleDaikinOrderFiles,
    });
  };

  toggleDialogSalesApproveFile = () => {
    this.setState({
      visibleSalesApproveFile: !this.state.visibleSalesApproveFile,
    });
  };

  toggleDialogAdressInfo = () => {
    this.setState({
      visibleAddressInfo: !this.state.visibleAddressInfo,
    });
  };
  toggleDialogAdressInsert = () => {
    this.setState({
      visibleAddressInsert: !this.state.visibleAddressInsert,
    });
  };

  toggleDialogResponsiblePerson = () => {
    this.setState({
      visibleResponsiblePerson: !this.state.visibleResponsiblePerson,
    });
  };

  toggleDialogOrderCopy = () => {
    this.setState({
      visibleOrderCopy: !this.state.visibleOrderCopy,
    });
  };

  calculateCampaignDiscount = () => {
    this.props.calculateCampaignDiscount(
      this.props.order._order.brY_SIPARIS_ID
    );
  };

  sendForApproval = () => {
    this.isSendForApproval = true;
    var satisOnay = {
      ISLEM: "ONAYA_GONDER",
      BRY_SIPARIS_ID: this.props.order._order.brY_SIPARIS_ID,
    };

    this.props.executeOnayHierarchy(satisOnay);
  };

  cancelAproval = () => {
    var satisOnay = {
      ISLEM: "YENI_DURUMUNA_GETIR",
      BRY_SIPARIS_ID: this.props.order._order.brY_SIPARIS_ID,
    };
    this.props.executeOnayIptal(satisOnay);
  };

  refreshProductList = () => {
    this.props.getOrderProducts(this.props.match.params.orderId);
  };

  sendToMDD = () => {
    var orderProducts = this.props.orderProductList.orderProducts;
    var hasRasyator = orderProducts.find((a) =>
      ["RADYATOR-DAIKIN", "RADYATOR-AIRFEL", "RADYATOR-DAYLUX"].includes(
        a.discounT_URUN_GRUBU
      )
    );

    var hasSatisOnayDoc = this.props.orderFiles.files.find(
      (a) => a.filetype === "SIPARIS_SATIS_ONAY"
    );

    if (!hasSatisOnayDoc && hasRasyator) {
      defaultAlertMessages.MessageDetailWarning =
        "Siparişi Cop'a gönderebilmek için satış onay dokümanını yüklemeniz gerekmektedir.";
      defaultAlertMessages.Ok = "Tamam";

      confirmAlert({
        customUI: ({ onClose }) => {
          return getWarningMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      if (
        this.props.orderFiles.files.length === 0 &&
        this.props.order._order.satiS_DEPARTMANI_KODU === "EXPORT_UPG"
      ) {
        defaultAlertMessages.MessageDetailWarning =
          "Siparişi Cop'a gönderebilmek için sipariş formunun imzalanmış halini sisteme yüklemeniz gerekmektedir.";
        defaultAlertMessages.Ok = "Tamam";

        confirmAlert({
          customUI: ({ onClose }) => {
            return getWarningMessage(onClose, defaultAlertMessages);
          },
        });
      } else {
        var satisOnay = {
          ISLEM: "EXPORT_MDD_GONDER",
          BRY_SIPARIS_ID: this.props.order._order.brY_SIPARIS_ID,
        };
        this.props.executeOnayIptal(satisOnay);
      }
    }
  };

  goApproveReject = () => {
    this.props.history.push(
      BASE_URL +
        "order-approval/" +
        this.props.order._order.brY_SIPARIS_ID +
        "/" +
        this.props.order._order.dkN_BRY_SIPARIS_SATIS_ONAY_ID
    );
  };

  render() {
    const order = this.props.order._order;
    const userSalesRegions = this.props.userSalesRegionList.userSalesRegions;
    if (
      !this.props.order.fetching &&
      !this.props.userSalesRegionList.fetching &&
      this.props.match.params.orderId !== "0" &&
      (userSalesRegions.length === 0 ||
        userSalesRegions.find(
          (a) => a.satiS_BOLGE_KODU === order.bayI_SATIS_BOLGE_KODU
        )?.yetki !== "TEKLIF_DETAYI_GOREBILIR")
    ) {
      return (
        <div>
          Bu bayinin siparişini görmek için yetkiniz bulunmamaktadır. Lütfen Dex
          Admin ile iletişime geçiniz.
        </div>
      );
    }
    return (
      <main style={{ flexGrow: "1", padding: "40px" }}>
        {(this.props.orderApproval.fetching ||
          this.props.campaignDiscountCalculate.fetching ||
          this.props.order.fetching) &&
          LoadingPanel}
        {this.state.visibleOrderCopy ? (
          <OrderCopy toggleDialog={this.toggleDialogOrderCopy}></OrderCopy>
        ) : undefined}
        {this.state.visibleOrderReport ? (
          <OrderReport
            toggleDialog={this.toggleDialogOrderReport}
            reportName={
              this.props.order._order.satiS_DEPARTMANI_KODU === "EXPORT_UPG"
                ? "OrderExportReport"
                : "OrderReport"
            }
            orderId={this.props.order._order.brY_SIPARIS_ID}
            title="Sipariş Formu"
            reportRelative={true}
          ></OrderReport>
        ) : (
          ""
        )}
        {this.state.visibleOrderRPAReport ? (
          <OrderReport
            toggleDialog={this.toggleDialogOrderRPAReport}
            reportName="RPAReport"
            title="Sipariş RPA Formu"
            orderId={this.props.order._order.brY_SIPARIS_ID}
            reportRelative={true}
          ></OrderReport>
        ) : (
          ""
        )}
        {this.state.visibleOrderFiles ? (
          <OrderFiles
            toggleDialog={this.toggleDialogOrderFiles}
            title="Sipariş Dosyaları"
            orderData={this.props.order._order}
            fileType="SIPARIS"
            auth={this.props.auth}
          ></OrderFiles>
        ) : (
          ""
        )}
        {this.state.visibleDaikinOrderFiles ? (
          <OrderFiles
            toggleDialog={this.toggleDialogDaikinOrderFiles}
            title="Daikin Sipariş Dosyaları"
            orderData={this.props.order._order}
            fileType="SIPARIS_DAIKIN"
            auth={this.props.auth}
          ></OrderFiles>
        ) : (
          ""
        )}
        {this.state.visibleSalesApproveFile ? (
          <OrderFiles
            toggleDialog={this.toggleDialogSalesApproveFile}
            title="Satış Onay Dokümanı"
            orderData={this.props.order._order}
            fileType="SIPARIS_SATIS_ONAY"
            auth={this.props.auth}
          ></OrderFiles>
        ) : (
          ""
        )}
        {this.state.visibleAddressInfo ? (
          <OrderAddressInfo
            toggleDialog={this.toggleDialogAdressInfo}
            title="Sipariş Adres Bilgileri"
            adresBasligi={this.props.order._order.sevK_ADRESI_TIPI}
            bayiKodu={this.props.order._order.bayI_KODU}
            orderId={this.props.match.params.orderId}
            order={this.props.order._order}
          ></OrderAddressInfo>
        ) : (
          ""
        )}
        {this.state.visibleAddressInsert ? (
          <CustomerAddressUpdate
            toggleDialog={this.toggleDialogAdressInsert}
            title="Müşteri Adres Bilgileri"
            type={"siparis-insert"}
            isExport={
              this.props.order._order.satiS_DEPARTMANI_KODU === "EXPORT_UPG"
            }
            orderId={this.props.match.params.orderId}
            bayiKodu={this.props.order._order.bayI_KODU}
          ></CustomerAddressUpdate>
        ) : (
          ""
        )}
        {this.state.visibleResponsiblePerson ? (
          <OrderResposiblePerson
            toggleDialog={this.toggleDialogResponsiblePerson}
            title="Sipariş Yetkili Kişi Bilgileri"
            orderId={this.props.match.params.orderId}
          ></OrderResposiblePerson>
        ) : (
          ""
        )}
        {this.props.order.fetching || (
          <div>
            {" "}
            <h3>
              {this.props.match.params.orderId === "0"
                ? "Yeni Sipariş"
                : "Sipariş Detayı"}
            </h3>
            <hr></hr>
            {this.props.orderProductList.fetching === false ? (
              <OrderProps
                orderId={this.props.match.params.orderId}
                orderData={this.props.order._order}
                submitFunc={this.onSubmit}
                orderProductData={this.props.orderProductList.orderProducts}
              ></OrderProps>
            ) : undefined}
            {/*orderProductData={*/}
            {/*    this.props.orderProductList.fetching === false*/}
            {/*        ? this.props.orderProductList.orderProducts*/}
            {/*        : []*/}
            {/*}*/}
            {this.props.match.params.orderId === "0" ? undefined : (
              <div className="btn-group">
                {/* <hr /> */}
                <Button
                  type="button"
                  color="warning"
                  size="sm"
                  onClick={this.toggleDialogOrderCopy}
                >
                  <i className="k-icon k-i-copy"></i> SIPARISI KOPYALA
                </Button>
                {(this.props.order._order.satiS_ONAY_DURUM === "ONAYLI" ||
                  this.props.order._order.satiS_ONAY_DURUM === "TEKLIF" ||
                  this.props.order._order.satiS_DEPARTMANI_KODU ===
                    "EXPORT_UPG") && (
                  <Button
                    type="button"
                    color="info"
                    size="sm"
                    onClick={this.toggleDialogOrderReport}
                  >
                    <i className="k-icon k-i-graph"></i> SIPARIS FORMU
                  </Button>
                )}
                {this.props.order._order.sipariS_TURU === "KAMPANYA" &&
                  this.props.order._order.kampanyA_HESAPLANDI !== "EVET" && (
                    <Button
                      type="button"
                      color="info"
                      size="sm"
                      onClick={() => this.calculateCampaignDiscount()}
                    >
                      <i className="k-icon k-i-pencil"></i> KAMPANYA HESAPLA
                    </Button>
                  )}
                {(this.props.order._order.satiS_ONAY_DURUM === "YENI" ||
                  this.props.order._order.satiS_ONAY_DURUM === "RED") && (
                  <Button
                    type="button"
                    color="primary"
                    size="sm"
                    onClick={() => this.sendForApproval()}
                  >
                    <i className="k-icon k-i-redo"></i> ONAYA GÖNDER
                  </Button>
                )}
                <Button
                  type="button"
                  color="secondary"
                  size="sm"
                  onClick={this.toggleDialogOrderFiles}
                >
                  <i className="k-icon k-i-folder-open"></i> SIPARIS DOSYALARI
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  size="sm"
                  onClick={this.toggleDialogDaikinOrderFiles}
                >
                  <i className="k-icon k-i-folder-open"></i> DAIKIN SIPARIS
                  DOSYALARI
                </Button>
                {this.props.order._order.sevK_ADRESI_TIPI !== "SAP_ADRES" &&
                  this.props.order._order.sevK_ADRESI_TIPI !== "YENI_ADRES" && (
                    <Button
                      type="button"
                      color="warning"
                      size="sm"
                      onClick={this.toggleDialogAdressInfo}
                    >
                      <i className="k-icon k-i-marker-pin"></i> ADRES BİLGİLERİ
                    </Button>
                  )}
                {this.props.order._order.sevK_ADRESI_TIPI === "YENI_ADRES" && (
                  <Button
                    type="button"
                    color="warning"
                    size="sm"
                    onClick={this.toggleDialogAdressInsert}
                  >
                    <i className="k-icon k-i-marker-pin"></i> ADRES BİLGİLERİ
                  </Button>
                )}
                <Button
                  type="button"
                  color="danger"
                  size="sm"
                  onClick={this.toggleDialogResponsiblePerson}
                >
                  <i className="k-icon k-i-user"></i> YETKİLİ KİŞİ BİLGİLERİ
                </Button>
                {this.props.order._order.satiS_ONAY_DURUM === "ONAYDA" && (
                  <Button
                    color="warning"
                    type="button"
                    size="sm"
                    onClick={this.cancelAproval}
                  >
                    <span className="k-icon k-i-reset"></span> ONAY SÜRECİNİ
                    GERİ AL
                  </Button>
                )}
                {this.props.order._order.satiS_ONAY_DURUM === "TEKLIF" && (
                  <Button
                    color="primary"
                    type="button"
                    size="sm"
                    onClick={this.sendToMDD}
                  >
                    <span className="k-icon k-i-redo"></span> COP'A GONDER
                  </Button>
                )}
                {this.props.order._order.satiS_ONAY_DURUM === "ONAYDA" &&
                this.props.order._order.satiS_ONAYCI_KULLANICI_ID?.toString() ===
                  this.props.auth.id ? (
                  <Button
                    color="primary"
                    type="button"
                    size="sm"
                    style={{ marginLeft: "5px" }}
                    onClick={this.goApproveReject}
                  >
                    <span class="k-icon k-i-edit"></span> ONAY/RED İŞLEMİ
                  </Button>
                ) : undefined}
                {this.props.order._order.satiS_ONAY_DURUM === "TEKLIF" ? (
                  <Button
                    type="button"
                    color="secondary"
                    size="sm"
                    style={{ marginLeft: "5px" }}
                    onClick={this.toggleDialogSalesApproveFile}
                  >
                    <i className="k-icon k-i-folder-open"></i>SATIŞ ONAY
                    DOKÜMANI
                  </Button>
                ) : undefined}
              </div>
            )}
            <hr></hr>
            {this.props.order &&
            this.props.order._order &&
            this.props.order._order.brY_SIPARIS_ID &&
            this.props.order._order.brY_SIPARIS_ID !== 0 &&
            this.props.orderProductList.fetching === false ? (
              <div>
                {(this.props.order.fetching ||
                  this.props.orderProductList.fetching ||
                  this.props.dealerProductGroupList.fetching) &&
                  LoadingPanel}
                <OrderProductList
                  sevkAdresTipi={this.props.order._order.sevK_ADRESI_TIPI}
                  orderId={this.props.match.params.orderId}
                  orderProductData={this.props.orderProductList.orderProducts}
                  refreshFunc={this.refreshProductList}
                ></OrderProductList>
                <hr></hr>
              </div>
            ) : undefined}
          </div>
        )}

        <br></br>
      </main>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    order: state.orderReducer,
    dealerProductGroupList: state.dealerProductGroupListReducer,
    campaignDiscountCalculate: state.campaignDiscountCalculateReducer,
    orderProductList: state.orderProductListReducer,
    orderApproval: state.orderApprovalReducer,
    orderFiles: state.orderFilesReducer,
    userSalesRegionList: state.userSalesRegionsReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getOrder: bindActionCreators(getOrder, dispatch),
    insertOrder: bindActionCreators(insertOrder, dispatch),
    updateOrder: bindActionCreators(updateOrder, dispatch),
    calculateCampaignDiscount: bindActionCreators(
      calculateCampaignDiscount,
      dispatch
    ),
    executeOnayHierarchy: bindActionCreators(executeOnayHierarchy, dispatch),
    executeOnayIptal: bindActionCreators(executeOnayIptal, dispatch),
    executeSapIslemleri: bindActionCreators(executeSapIslemleri, dispatch),
    fetchDealerProductGroup: bindActionCreators(
      fetchDealerProductGroup,
      dispatch
    ),
    getOrderProducts: bindActionCreators(getOrderProducts, dispatch),
    fetchOrderFiles: bindActionCreators(fetchOrderFiles, dispatch),
    getUserSalesRegions: bindActionCreators(getUserSalesRegions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderUpdate);
