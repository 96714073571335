import { Row, Col } from "reactstrap";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import {
  DATALISTEVADESI,
  DATABRYSATISDEPARTMAN,
  DATASATISDEPARTMAN,
  DATABANKATAKSIT,
} from "../../config/globalVariables";

export function OrderCopProps({ orderData }) {
  var listeFiyatiVadesi = DATALISTEVADESI.find(
    (a) => a.id === orderData.listE_FIYATI_VADESI
  );
  return (
    <Row>
      <Col lg="4" md="4" xs="12">
        <Input
          label="Sipariş No"
          name="brY_SIPARIS_ID"
          id="brY_SIPARIS_ID"
          style={{ width: "100%" }}
          value={orderData.brY_SIPARIS_ID}
          readOnly={true}
        ></Input>
        <Input
          name="bayI_ADI"
          label="Bayi Adı"
          style={{ width: "100%" }}
          value={`${orderData.bayI_ADI} (${orderData.bayI_KODU})`}
          readOnly={true}
        />
        <Input
          name="satiS_DEPARTMANI_KODU"
          label="Satış Departmanı"
          style={{ width: "100%" }}
          value={"UPG"}
          readOnly={true}
        />
        <Input
          name="satiS_DEPARTMANI_KODU"
          label="Ana Ürün Grubu"
          style={{ width: "100%" }}
          value={
            orderData.satiS_DEPARTMANI_KODU
              ? DATABRYSATISDEPARTMAN.find(
                  (dbry) => dbry.id === orderData.satiS_DEPARTMANI_KODU
                ).text
              : ""
          }
          readOnly={true}
        />
        <Input
          name="brY_KANAL"
          label="Marka"
          style={{ width: "100%" }}
          value={orderData.brY_KANAL}
          readOnly={true}
        />
        <Input
          label="Satış Onay Durum"
          name="satiS_ONAY_DURUM"
          id="satiS_ONAY_DURUM"
          style={{ width: "100%" }}
          value={orderData.satiS_ONAY_DURUM}
          readOnly={true}
        ></Input>
        <DateInput
          label="Sipariş Giriş Tarihi"
          format="dd.MM.yyyy HH:mm"
          formatPlaceholder="formatPattern"
          value={new Date(orderData.crE_DATE)}
          style={{ width: "100%" }}
          readOnly={true}
        />
        <Input
          name="parA_BIRIMI"
          label="Para Birimi"
          style={{ width: "100%" }}
          value={orderData.parA_BIRIMI}
          readOnly={true}
        />
        <Input
          label="Satış Temsilcisi"
          name="satiS_TEMSILCISI"
          id="satiS_TEMSILCISI"
          style={{ width: "100%" }}
          value={orderData.satiS_TEMSILCISI}
          readOnly={true}
        ></Input>
        {orderData.kopyalanaN_SIPARIS_ID ? (
          <Input
            label="Kopyalanan Sipariş No"
            name="kopyalanaN_SIPARIS_ID"
            id="kopyalanaN_SIPARIS_ID"
            style={{ width: "100%" }}
            value={orderData.kopyalanaN_SIPARIS_ID}
            readOnly={true}
          ></Input>
        ) : undefined}
        {orderData.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
          <div>
            <NumericTextBox
              label="Freight"
              name="navluN_FIYATI"
              id="navluN_FIYATI"
              style={{ width: "100%" }}
              value={orderData.navluN_FIYATI ? orderData.navluN_FIYATI : 0}
              readOnly={true}
            ></NumericTextBox>
            <NumericTextBox
              label="Insurance"
              name="sigortA_UCRETI"
              id="sigortA_UCRETI"
              style={{ width: "100%" }}
              value={orderData.sigortA_UCRETI ? orderData.sigortA_UCRETI : 0}
              readOnly={true}
            ></NumericTextBox>
            <Input
              label="Purchase Order Number"
              name="satiN_ALMA_SIPARIS_NO"
              id="satiN_ALMA_SIPARIS_NO"
              style={{ width: "100%" }}
              value={orderData.satiN_ALMA_SIPARIS_NO}
              readOnly={true}
            ></Input>
          </div>
        ) : undefined}
      </Col>
      <Col lg={{ size: 4 }} md="4" xs="12">
        <Input
          name="sipariS_TURU"
          label="Sipariş Türü"
          style={{ width: "100%" }}
          value={orderData.sipariS_TURU}
          readOnly={true}
        />
        {orderData.sipariS_TURU === "KAMPANYA" ? (
          <div>
            <Input
              name="kampanyA_KODU"
              label="Kampanya"
              style={{ width: "100%" }}
              value={orderData.kampanyA_KODU}
              readOnly={true}
            />
            <Input
              label="Kampanya Bilgisi"
              name="kampanyA_KOSULU"
              id="kampanyA_KOSULU"
              style={{ width: "100%" }}
              value={orderData.kampanyA_KOSULU}
              readOnly={true}
            ></Input>
          </div>
        ) : undefined}
        {orderData.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
          <div>
            <Input
              name="exporT_SIPARIS_TIPI"
              label="Export Sipariş Tipi"
              style={{ width: "100%" }}
              value={orderData.exporT_SIPARIS_TIPI}
              readOnly={true}
            />
            <Input
              name="exporT_SIPARIS_TURU"
              label="Export Sipariş Türü"
              style={{ width: "100%" }}
              value={orderData.exporT_SIPARIS_TURU}
              readOnly={true}
            />
            <Input
              name="exporT_BOLGE_KODU"
              label="Export Bölge Kodu"
              style={{ width: "100%" }}
              value={orderData.exporT_BOLGE_KODU}
              readOnly={true}
            />
            <Input
              name="incoterms"
              label="Incoterms"
              style={{ width: "100%" }}
              value={orderData.incoterms}
              readOnly={true}
            />
            <div>Incoterms2</div>
            <TextArea
              placeholder="Incoterms 2"
              id="incotermS2"
              name="incotermS2"
              autoSize={false}
              style={{ width: "100%" }}
              value={orderData.incotermS2}
              readOnly={true}
            ></TextArea>
            <Input
              name="sevK_YAPILACAK_ULKE"
              label="Sevk Yapılacak Ülke"
              style={{ width: "100%" }}
              value={orderData.sevK_YAPILACAK_ULKE}
              readOnly={true}
            />
            <Input
              name="odemE_KOSULU"
              label="Ödeme Koşulu"
              style={{ width: "100%" }}
              value={orderData.odemE_KOSULU}
              readOnly={true}
            />
          </div>
        ) : undefined}
        {orderData.satiS_DEPARTMANI_KODU === "HEATING" ? (
          <Input
            name="listE_FIYATI_VADESI"
            label="Lis. Fiy. Vadesi"
            style={{ width: "100%" }}
            value={listeFiyatiVadesi ? listeFiyatiVadesi.text : null}
            readOnly={true}
          />
        ) : undefined}
        {orderData.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
          <Input
            name="odemE_VADESI_TIPI"
            label="Ödeme Vadesi Tipi"
            style={{ width: "100%" }}
            value={orderData.odemE_VADESI_TIPI}
            readOnly={true}
          />
        ) : undefined}
        {orderData.odemE_VADESI_TIPI === "FATURA_VADESI" ? (
          <Input
            name="faturA_VADESI"
            label="Fatura Vadesi"
            style={{ width: "100%" }}
            value={orderData.faturA_VADESI}
            readOnly={true}
          />
        ) : undefined}
        {orderData.odemE_VADESI_TIPI === "NOKTA_VADE" ? (
          <div>
            <div>Ödeme Vadesi Tarihi</div>
            <DatePicker
              label="Ödeme Vadesi Tarihi"
              name="odemE_VADE_TARIHI"
              format="dd.MM.yyyy"
              formatPlaceholder="formatPattern"
              min={new Date()}
              value={
                orderData.odemE_VADE_TARIHI != null
                  ? orderData.odemE_VADE_TARIHI === ""
                    ? null
                    : new Date(orderData.odemE_VADE_TARIHI)
                  : null
              }
              style={{ width: "100%" }}
              readOnly={true}
            />
          </div>
        ) : undefined}
        <Input
          name="odemE_TIPI"
          label="Ödeme Tipi"
          style={{ width: "100%" }}
          value={orderData.odemE_TIPI}
          readOnly={true}
        />
        {orderData.bankA_ADI ? (
          <Input
            name="bankA_ADI"
            label="Banka"
            style={{ width: "100%" }}
            value={orderData.bankA_ADI}
            readOnly={true}
          />
        ) : undefined}
        {orderData.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
          <Input
            name="taksiT_SAYISI"
            label="Taksit Sayısı"
            style={{ width: "100%" }}
            value={
              orderData.taksiT_SAYISI
                ? DATABANKATAKSIT.find(
                    (a) => a.id === orderData.taksiT_SAYISI.toString()
                  )?.text
                : null
            }
            readOnly={true}
          />
        ) : undefined}
        <Input
          name="toplaM_HACIM"
          label="Toplam Hacim (m³)"
          style={{ width: "100%" }}
          value={orderData.toplaM_HACIM}
          readOnly={true}
          disabled={true}
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <Input
          label="Firma Yetkili Kişi"
          name="firmA_YETKILI_KISI"
          id="firmA_YETKILI_KISI"
          style={{ width: "100%" }}
          value={orderData.firmA_YETKILI_KISI}
          readOnly={true}
        ></Input>
        <Input
          label="Sevk Sorumlusu/Numarası"
          name="sevK_EDILECEK_KISI"
          id="sevK_EDILECEK_KISI"
          style={{ width: "100%" }}
          value={orderData.sevK_EDILECEK_KISI}
          readOnly={true}
        ></Input>
        <div>Talep Edilen Sevk Tarihi</div>
        <DateInput
          label="Talep Edilen Sevk Tarihi"
          name="sevK_TARIHI"
          format="dd.MM.yyyy"
          formatPlaceholder="formatPattern"
          value={
            orderData.sevK_TARIHI != null
              ? orderData.sevK_TARIHI === ""
                ? null
                : new Date(orderData.sevK_TARIHI)
              : null
          }
          style={{ width: "100%" }}
          readOnly={true}
        />
        <Input
          name="sevK_ADRESI_TIPI"
          label="Sevk Adresi Tipi"
          style={{ width: "100%" }}
          value={orderData.sevK_ADRESI_TIPI}
          readOnly={true}
        />
        <div>Sevk Notu</div>
        <TextArea
          placeholder="Sevk Notu"
          id="sevK_ADRESI"
          name="sevK_ADRESI"
          autoSize={false}
          style={{ width: "100%" }}
          value={orderData.sevK_ADRESI}
          readOnly={true}
        ></TextArea>
        <div>
          <div>Açıklama</div>
          <TextArea
            placeholder="Açıklama"
            id="aciklama"
            name="aciklama"
            autoSize={false}
            style={{ width: "100%" }}
            value={orderData.aciklama}
            readOnly={true}
          ></TextArea>
        </div>
        {orderData.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
          <div>
            <div>Proforma Açıklama</div>
            <TextArea
              placeholder="Proforma Açıklama"
              id="proformA_ACIKLAMA"
              name="proformA_ACIKLAMA"
              autoSize={false}
              style={{ width: "100%" }}
              rows={6}
              value={
                !orderData.proformA_ACIKLAMA ||
                orderData.proformA_ACIKLAMA === ""
                  ? "Brand: \nLATEST DATE OF SHIPMENT: \nOFFER VALIDATY: \nAFTER SALES CONDITIONS: \nPACKAGING: \nADDITIONAL INFORMATION:"
                  : orderData.proformA_ACIKLAMA
              }
              readOnly={true}
            ></TextArea>
          </div>
        ) : undefined}
        <div>
          <div>Plant</div>
          <TextArea
            placeholder="Plant"
            id="plant"
            name="plant"
            autoSize={false}
            style={{ width: "100%" }}
            value={orderData.plant}
            readOnly={true}
          ></TextArea>
        </div>
      </Col>
    </Row>
  );
}
