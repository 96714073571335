import React, { useEffect, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import {
  DATASEVKEDILMEMENEDENI,
  DATASEVKEDILMEMEDETAYI,
  DATASEVKSEKLI,
  DATASEVKARACTIPI,
  DATASEVKZAMANI,
  DATAYESILBINASERTIFIKA,
  DATAEXPORTSEVKSEKLI,
} from "../../config/globalVariables";
import { Row, Col, Button } from "reactstrap";
import CommonTextArea from "../sys-common/CommonTextArea";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import OfferService from "../../services/srv-offer/OfferService";
import CustomerTempDropDownList from "../sys-common/CustomerTempDropDownList";
import RegisteredTrademarkDropDownList from "../sys-common/RegisteredTrademarkDropDownList";
import YesNoDropDownList from "../sys-common/YesNoDropDownList";
import { replacePleaseSelect } from "../../helpers/utils";
import SalesRepresentativeDropDownList from "../sys-common/SalesRepresentativeDropDownList";
import GreenBuildingCompanyDropDownList from "../sys-common/GreenBuildingCompanyDropDownList";

const MyOrderUpdateMrc = (props) => {
  const [data, setData] = useState(props.data);

  return (
    <Window
      title={props.title}
      onClose={props.toggleDialog}
      initialHeight={"80vh"}
      initialWidth={"30%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "fixed",
        zIndex: 10000,
      }}
    >
      <CommonDropDownList
        id="sevK_EDILMEME_NEDENI"
        label="Sevk Edilmeme Nedeni"
        data={DATASEVKEDILMEMENEDENI}
        onChange={(e) =>
          setData({ ...data, sevK_EDILMEME_NEDENI: e.target.value.id })
        }
        value={data?.sevK_EDILMEME_NEDENI}
      />

      <CommonDropDownList
        id="sevK_EDILMEME_DETAYI"
        label="Sevk Edilmeme Detayı"
        data={[
          { text: "Lütfen Seçiniz", id: "-1" },
          ...DATASEVKEDILMEMEDETAYI.filter(
            (child) => child?.parent === data?.sevK_EDILMEME_NEDENI
          ),
        ]}
        onChange={(e) =>
          setData({ ...data, sevK_EDILMEME_DETAYI: e.target.value.id })
        }
        value={data?.sevK_EDILMEME_DETAYI}
      />

      <CommonTextArea
        id="sevK_EDILMEME_ACIKLAMA"
        label="Açıklama"
        onChange={(e) => {
          setData({ ...data, sevK_EDILMEME_ACIKLAMA: e.value });
        }}
        value={data?.sevK_EDILMEME_ACIKLAMA}
      ></CommonTextArea>

      <CommonDropDownList
        id="sevK_SEKLI"
        label="Sevk Şekli"
        data={
          data?.satiS_DEPARTMANI_KODU?.includes("EXPORT")
            ? DATAEXPORTSEVKSEKLI
            : DATASEVKSEKLI
        }
        onChange={(e) =>
          setData({
            ...data,
            sevK_SEKLI: e.target.value.id,
            sevK_PARTI_ACIKLAMA: null,
          })
        }
        value={data?.sevK_SEKLI}
      />

      {data.sevK_SEKLI === "PARTI_PARTI" && (
        <CommonTextArea
          id="sevK_PARTI_ACIKLAMA"
          label="Parti Açıklama"
          onChange={(e) => setData({ ...data, sevK_PARTI_ACIKLAMA: e.value })}
          value={data?.sevK_PARTI_ACIKLAMA}
        ></CommonTextArea>
      )}

      {!data?.satiS_DEPARTMANI_KODU?.includes("EXPORT") && (
        <CommonDropDownList
          id="sevK_ARAC_TIPI"
          label="Sevk Aracı Tipi"
          data={DATASEVKARACTIPI}
          onChange={(e) =>
            setData({ ...data, sevK_ARAC_TIPI: e.target.value.id })
          }
          value={data?.sevK_ARAC_TIPI}
        />
      )}

      <CommonDropDownList
        id="sevK_ZAMANI"
        label="Sevk Zamanı"
        data={DATASEVKZAMANI}
        onChange={(e) => setData({ ...data, sevK_ZAMANI: e.target.value.id })}
        value={data?.sevK_ZAMANI}
      />

      {!props.isCop && (
        <>
          <CommonDropDownList
            id="yesiL_BINA_SERTIFIKA"
            label="Yeni Bina Sertifika"
            data={DATAYESILBINASERTIFIKA}
            onChange={(e) =>
              setData({ ...data, yesiL_BINA_SERTIFIKA: e.target.value.id })
            }
            value={data?.yesiL_BINA_SERTIFIKA}
          />

          <GreenBuildingCompanyDropDownList
            id="yesiL_BINA_FIRMA_KODU"
            label="Yeşil Bina Sert. Dan. Firma"
            value={data.yesiL_BINA_FIRMA_KODU}
            onChange={(e) => {
              console.log(e.value);
              setData({ ...data, yesiL_BINA_FIRMA_KODU: e?.value?.firmA_KODU });
            }}
          />

          <CustomerTempDropDownList
            id="montaJ_YAPAN_FIRMA_KODU"
            label="Montaj Yapan Firma"
            value={data.montaJ_YAPAN_FIRMA_KODU}
            onChange={(e) => {
              setData({ ...data, montaJ_YAPAN_FIRMA_KODU: e?.value?.muS_KODU });
            }}
          />

          <YesNoDropDownList
            id="tescillI_MARKA_MI"
            label="Tescilli Marka"
            onChange={(e) =>
              setData({
                ...data,
                tescillI_MARKA_MI: e.value,
                tescillI_MARKA_KODU: null,
              })
            }
            value={data?.tescillI_MARKA_MI}
          />

          {data.tescillI_MARKA_MI === "EVET" && (
            <RegisteredTrademarkDropDownList
              id="tescillI_MARKA_KODU"
              label="Tescilli Marka"
              value={data.tescillI_MARKA_KODU}
              onChange={(e) =>
                setData({ ...data, tescillI_MARKA_KODU: e?.value?.markA_KODU })
              }
            />
          )}
        </>
      )}

      <SalesRepresentativeDropDownList
        id="projE_ASAMASI_AKTARIM_YAPAN_KULLANICI_ID"
        label="Aktarım Yapan Kullanıcı"
        value={data.projE_ASAMASI_AKTARIM_YAPAN_KULLANICI_ID}
        satisTemsilcileri={"all"}
        onChange={(e) =>
          setData({
            ...data,
            projE_ASAMASI_AKTARIM_YAPAN_KULLANICI_ID: e?.value?.kullanicI_ID,
          })
        }
      />

      <SalesRepresentativeDropDownList
        id="projE_CALISMASI_YAPAN_KISI"
        label="Proje Çalışması Yapan Kişi"
        value={data.projE_CALISMASI_YAPAN_KISI}
        itemKey={"adsoyad"}
        onChange={(e) => {
          console.log(e.value);
          setData({
            ...data,
            projE_CALISMASI_YAPAN_KISI: e?.value?.adsoyad?.toString(),
          });
        }}
      />

      <Row>
        <Col lg="12" md="12" xs="12" style={{ marginTop: "40px" }}>
          <Button
            color="secondary"
            type="button"
            size="sm"
            onClick={props.toggleDialog}
          >
            Vazgeç
          </Button>
          <Button
            type="button"
            color="primary"
            size="sm"
            style={{ width: "150px", float: "right" }}
            onClick={() =>
              OfferService.updateMyOrder(
                replacePleaseSelect(data),
                props.callBack
              )
            }
          >
            {" "}
            Kaydet
          </Button>
        </Col>
      </Row>
    </Window>
  );
};

export default MyOrderUpdateMrc;
