import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import axios from "axios";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import { getOrderProducts } from "./orderProductActions";

export function fetchOrder(dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_ORDER,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/siparis/get-list-by-filter?${toDataSourceRequestString(dataState)}`
        )
        .then((result) =>
          dataState.group && dataState.group.length
            ? translateDataSourceResultGroups(result.data)
            : result.data
        ),
    });
  };
}

export function fetchOrderDealer(dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_ORDER_DEALER,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/siparis/get-list-by-filter-dealer?${toDataSourceRequestString(
            dataState
          )}`
        )
        .then((result) =>
          dataState.group && dataState.group.length
            ? translateDataSourceResultGroups(result.data)
            : result.data
        ),
    });
  };
}

export function fetchOrdersForExcel(exports, columns, dataState) {
  var dataFilterState = {};
  dataFilterState.filter = dataState.filter;
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_ORDER,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/siparis/get-list-by-filter?${toDataSourceRequestString(
            dataFilterState
          )}`
        )
        .then((result) => {
          exports.save(result.data, columns);
          dispatch(fetchOrder(dataState));
        }),
    });
  };
}

export function changeOrder(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_ORDER,
      payload: event,
    });
  };
}

export function changeOrderDealer(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_ORDER_DEALER,
      payload: event,
    });
  };
}

export function setOrder(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_ORDER,
      payload: event,
    });
  };
}

export function setFaturaVadesi(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_FATURA_VADESI_TO_ORDER,
      payload: event,
    });
  };
}

export function setCampaignToOrder(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_CAMPAIGN_TO_ORDER,
      payload: data,
    });
  };
}

export function setDealerToOrder(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_DEALER_TO_ORDER,
      payload: event,
    });
  };
}

export function getOrder(orderId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ORDER,
      payload: orderId
        ? axios
            .get(
              `${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/get-by-id/${orderId}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}

export function insertOrder({ _order }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_ADD_ORDER,
      payload: axios
        .post(`${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/add-order`, {
          ..._order,
        })
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function updateOrder({ _order }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_ORDER,
      payload: axios
        .post(`${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/update-order`, {
          ..._order,
        })
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function deleteOrder(order) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_REMOVE_ORDER,
      payload: axios
        .post(`${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/delete-order`, {
          ...order,
        })
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function cancelOrder(order, dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_CANCEL_ORDER,
      payload: axios
        .post(`${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/cancel-order`, {
          ...order,
        })
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function cancelOrderDealer(order, dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_CANCEL_ORDER_DEALER,
      payload: axios
        .post(`${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/cancel-order`, {
          ...order,
        })
        .then((result) => {
          dispatch(fetchOrderDealer(dataState));
          return result.data;
        }),
    });
  };
}

export function calculateCampaignDiscount(orderId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CALCULATE_CAMPAIGN_DISCOUNT,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/siparis/calculate-campaign-discount/${orderId}`
        )
        .then((result) => {
          dispatch(getOrderProducts(orderId));
          dispatch(getOrder(orderId));
          return result.data;
        }),
    });
  };
}

export function executeOnayHierarchy(satisOnay) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_APPROVAL_HIERARCHY,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/execute-onay-hiyerarsi`,
          {
            ...satisOnay,
          }
        )
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function executeSapIslemleri(sapIslemleri) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_APPROVAL_HIERARCHY,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/siparis/execute-sap-giris-islemleri`,
          {
            ...sapIslemleri,
          }
        )
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function executeOnaySorgula(satisOnay) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EXECUTE_ORDER_APPROVAL_QUERY,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/execute-onay-hiyerarsi`,
          {
            ...satisOnay,
          }
        )
        .then((result) => {
          return result.data;
        }),
    });
  };
}
export function executeOnayIptal(satisOnay) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EXECUTE_ORDER_APPROVAL_CANCEL,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/execute-onay-hiyerarsi`,
          {
            ...satisOnay,
          }
        )
        .then((result) => {
          dispatch(getOrder(satisOnay.BRY_SIPARIS_ID));
          return result.data;
        }),
    });
  };
}

export function copyOrder(dto, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_COPY_ORDER,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/execute-copy-order`,
          dto
        )
        .then((result) => {
          if (callback) {
            callback(result.data);
          }
          return result.data;
        }),
    });
  };
}
